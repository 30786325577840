import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';
import config from '../../constant';
import { useState } from 'react';

const Footer = () => {

  const [isActive, setIsActive] = useState( );
  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
};
const handleClick = event => {
// 👇️ toggle isActive state on click
setIsActive(current => !current);
};

     return(
      <>
        <div className='footerSection'>
          {/* row-cols-4  */}
     <Container className='py-5'> 
     <footer className="row      ">
    <Col lg="3" >
      <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
       </a>
      <p className="text-white"><Image src={config.ImageUrl+"FLC-logo.png"}  fluid/></p>

     
    </Col>

    

    <Col lg="3" className='addressDiv'>
      <h5>Services</h5>
      <ul className="nav flex-column">
        <li className="nav-item mb-2"> <NavLink to="/crude_oil_light_heavy" >Crude oil light & heavy</NavLink>
</li>
        <li className="nav-item mb-2">  <NavLink to="/low_high_sulfer_gasoil" >low and high sulfer gasoil</NavLink></li>
        <li className="nav-item mb-2">  <NavLink to="/marine_gas_oil" >marine gas oil (dma)</NavLink></li>
        <li className="nav-item mb-2">  <NavLink to="/fuel_oil" >Fuel oil 100,180,280 and 380 cst</NavLink></li>
         
       </ul>
    </Col>
    <Col lg="4" className='addressDiv'>
    <h5> &nbsp;</h5>

       <ul className="nav flex-column">
        <li className="nav-item mb-2"> <NavLink to="/base_oils" >Base oils (sn150, sn500 and bright stocks)</NavLink>
</li>
        <li className="nav-item mb-2">  <NavLink to="/bitumen" >Bitumen (60 / 70 and 80 / 100)</NavLink></li>
        <li className="nav-item mb-2">  <NavLink to="/nafta_oil" >Nafta oil</NavLink></li>
        <li className="nav-item mb-2">  <NavLink to="/methanol_lpg" >Methanol and lpg</NavLink></li>
         
       </ul>
    </Col>

    <Col lg="2">
    <h5> &nbsp;</h5>

       <ul className="nav flex-column">
        <li className="nav-item mb-2"> <NavLink to="/" >Home</NavLink>
</li>
        <li className="nav-item mb-2">  <NavLink to="/about" >About</NavLink></li>
        <li className="nav-item mb-2">  <NavLink to="/contact" >Contact</NavLink></li>
         
       </ul>

        
       <p> <a href="#"   activeclassname="active" target='_blank'><i className='fa fa-facebook'></i></a> <a href="#"   activeclassname="active" target='_blank'><i className='fa fa-instagram'></i></a></p> 
        
 
       
    </Col>

    
  </footer>

  
    </Container>

        </div>

<div className='copyright py-3'>
  <Container><Row>
<Col lg='6' >
<p className='text-center'>Copyright ©️ {getYear()-1}-{getYear()} {config.SITE_NAME}. All Rights reserved.</p>

</Col>
<Col  lg='6'>
<p className='text-center'>  Developed by <a href="https://bicads.com/" target="_blank">BICADS</a></p>
</Col>
</Row></Container>
</div>

<a href="https://api.whatsapp.com/send?phone=+97142264420&amp;text=Hello we are interested in Lord Petroleum." className="float effetMoveGradient " target="_blank">

<i className="fa fa-whatsapp my-float "></i>

</a>
 
{/* <a href="https://www.instagram.com/babjifiltrations/" className="float_left effetMoveGradient " target="_blank">

<i className="fa fa-instagram my-float "></i>

</a> */}
</>
    );

}

export default Footer;
