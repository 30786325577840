import React, { Component,useState, useEffect }  from 'react';
 import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';
import config from '../../constant';

 

       class Header extends Component {
    
    render(){
    return(

      <div>
 <div className='topBar   '>
        <Container>
          <Row>
          <Col lg="9"xs="12" className="d-sm-block d-none">
          <Navbar>
            <Nav className=''>
            <li> <a  href={"mailto:"+ config.email_id} target='_blank'>
              <Image src={config.ImageUrl + "Location-mark.png"} /> {config.address} </a></li> 
        <li>  <a href={"mailto:"+ config.email_id}  target='_blank'><Image src={config.ImageUrl + "Email.png"} /> {config.email_id}</a></li>
        <li>  <a href={"tel:"+ config.contact1}  target='_blank'><Image src={config.ImageUrl + "phone-icons.png"} /> {config.contact1}</a></li>
          </Nav>
          </Navbar>

        </Col>
            <Col lg="3" xs="12" className='text-center'>
              <Navbar>
            <Nav className='mx-auto justify-content-center'>
            <li> <a  href="#"   activeclassname="active" target='_blank'><i className='fa fa-facebook'></i></a></li> 
        <li>  <a href="#"   activeclassname="active" target='_blank'><i className='fa fa-instagram'></i></a></li>
          </Nav>
          </Navbar>
            </Col>
          </Row>
        </Container>
      </div>      
        <Navbar collapseOnSelect expand="lg" className="justify-content-center" >
      <Container>
        <Col lg="3" md="12" sm="12">
        <Navbar.Brand> <NavLink to="/"   activeclassname="active"><Image src={config.ImageUrl+"FLC-logo.png"}  fluid/> </NavLink> </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='pull-right'/>
          {/* <Nav className="mx-auto justify-content-center py-3 d-none d-sm-block"  >
          <span>Certificate : ISO 9001 : 2015 | ISO 14001 : 2015 | MSME | SSI | NSIC  </span>

            
          </Nav> */}
          </Col>
          <Col lg="6">
          <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className='mx-auto'>
          <NavLink to="/"   activeclassname="active">Home</NavLink>
          <NavLink to="/about"   activeclassname="active">About Us</NavLink>
          <NavDropdown title="Services" id="basic-nav-dropdown"  renderMenuOnMount={true} align="end">
         
   <NavDropdown.Item href="" > 
  <NavLink to="/crude_oil_light_heavy"   activeclassname="sactive">Crude oil light & heavy</NavLink>
  
  </NavDropdown.Item>  

  <NavDropdown.Item href="" > <NavLink to="/low_high_sulfer_gasoil"   activeclassname="sactive">Low and high sulfer gasoil</NavLink></NavDropdown.Item>

 
  <NavDropdown.Item href="" > <NavLink to="/marine_gas_oil"   activeclassname="sactive">Marine gas oil (dma)</NavLink></NavDropdown.Item>
 
  <NavDropdown.Item href="" > <NavLink to="/fuel_oil"   activeclassname="sactive">Fuel oil 100,180,280 and 380 cst</NavLink></NavDropdown.Item>


  <NavDropdown.Item href="" > <NavLink to="/base_oils"   activeclassname="sactive">Base oils (sn150, sn500 and bright stocks)</NavLink></NavDropdown.Item>

  <NavDropdown.Item href="" > <NavLink to="/bitumen"   activeclassname="sactive">Bitumen (60 / 70 and 80 / 100)</NavLink></NavDropdown.Item>

  <NavDropdown.Item href="" > <NavLink to="/nafta_oil"   activeclassname="sactive">Nafta oil</NavLink></NavDropdown.Item>

  <NavDropdown.Item href="" > <NavLink to="/methanol_lpg"   activeclassname="sactive">Methanol and lpg</NavLink></NavDropdown.Item>



 </NavDropdown> 
 {/* <NavLink to="/presence"   activeclassname="active">Our Presence</NavLink> */}

              <NavLink to="/contact"   activeclassname="active">Contact Us</NavLink>
 
          </Nav>
          </Navbar.Collapse>

          </Col>
          <Col lg="3" className=' d-none'>

          <Nav className='justify-content-center'>
        <li> <a href="#"   activeclassname="active" target='_blank'><Image src={config.ImageUrl + "facebook-icon.png"} /></a></li> 
        <li>  <a href="#"   activeclassname="active" target='_blank'><Image src={config.ImageUrl + "Linked-in-icon.png"} /></a></li>
 
          </Nav>
          </Col>

       </Container>
    </Navbar>
    </div>
           );
}

  }
export default Header;
